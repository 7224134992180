import { Trans, withTranslation } from 'react-i18next';
import i18n from '../../i18n';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import { ButtonComponent, CardComponent, ContainerComponent, HeaderComponent, ParagraphComponent, SubtitleComponent, TitleComponent } from '../../components'

import './home.style.scss'

import solarPlantsImage from '../../assets/images/solarPlantsImage.webp';
import softwareImage from '../../assets/images/softwareImage.webp';
import heatPumpsImage from '../../assets/images/heatPumpsImage.webp';
import azuri from '../../assets/images/azuri.png'
import mitsubishi from '../../assets/images/mitsubishi.svg'
import gree from '../../assets/images/gree.svg'
import jinko from '../../assets/images/Jinko.png'
import growatt from '../../assets/images/Growatt.png'
import staubli from '../../assets/images/staublilogo.png'
import projoy from '../../assets/images/projoylogo.png'

import pula1 from '../../assets/images/references/pula1.JPG';
import buzet2 from '../../assets/images/references/buzet2.JPG';
import a1 from '../../assets/images/references/projekt2/a1.jpg';

function Home({ t }: { t: any }) {

  return (
    <div className='home'>
      <Helmet>
        <title>{t('home.meta.title')}</title>
        <meta name="description" content={t('home.meta.description')} />
      </Helmet>
      <Trans>
        <HeaderComponent home />
        <ContainerComponent>
          <CardComponent centered>
            <div className='items' id="solarItems">
              <div className="item">
                <h2 className="title"><SubtitleComponent centered ralewayBold className="title" white>{t('home.items.1.title')}</SubtitleComponent></h2>
                <h3 className="description"><ParagraphComponent className="description" dark>{t('home.items.1.subtitle')}</ParagraphComponent></h3>
              </div>
              <div className="item">
                <h2 className="title"> <SubtitleComponent centered ralewayBold className="title" white>{t('home.items.2.title')}</SubtitleComponent></h2>
                <h3 className="description"><ParagraphComponent className="description" dark>{t('home.items.2.subtitle')}</ParagraphComponent></h3>
              </div>
              <div className="item">
                <h2 className="title"><SubtitleComponent centered ralewayBold className="title" white>{t('home.items.3.title')}</SubtitleComponent></h2>
                <h3 className="description"><ParagraphComponent className="description" dark>{t('home.items.3.subtitle')}</ParagraphComponent></h3>
              </div>
            </div>
          </CardComponent>
          <div className="solarPanelPlants">
            <div className="titlePlants">
              <h2>
                <TitleComponent smaller>{t('home.solar.title')}</TitleComponent>
              </h2>
              <h3><SubtitleComponent>{t('home.solar.subtitle')} </SubtitleComponent></h3></div>
            <div className="buttonPlants">
              <Link to={"/" + i18n.resolvedLanguage + t('navbar.routes.solar')}><ButtonComponent style={{ marginRight: 20 }}>{t('misc.learnMore')}</ButtonComponent></Link>
            </div>
            <CardComponent className="typesContainer" style={{ padding: '2rem' }}>
              <div className="typesOfPlants">
                <SubtitleComponent secondary semiBold style={{ marginTop: 0 }} >{t('home.solar.types.subtitle')}</SubtitleComponent>
                <SubtitleComponent secondary >{t('home.solar.types.1')}</SubtitleComponent>
                <SubtitleComponent secondary >{t('home.solar.types.2')} </SubtitleComponent>
                <SubtitleComponent secondary >{t('home.solar.types.3')}</SubtitleComponent>
              </div>
            </CardComponent>
            <div className="imagePlants">
              <img src={solarPlantsImage} alt={t('home.solar.alt')} />
            </div>
          </div>
          <div className="solarEquipment">
            <div className="titleEquipment">
              <h2>
                <TitleComponent centered moreSmaller>{t('home.equipment.title')}</TitleComponent>
              </h2>
              <h3><SubtitleComponent style={{ textAlign: "center", marginTop: "1rem" }} >{t('home.equipment.subtitle')}</SubtitleComponent></h3>
            </div>
            <div className="typesContainer">
              <Link to={"/" + i18n.resolvedLanguage + t('navbar.routes.equipment') + "/" + t('solarEquipment.urlCategories.solar_panels')}>
                <ButtonComponent white image>
                  <h2>{t('home.equipment.types.1')}</h2>
                </ButtonComponent>
              </Link>
              <Link to={"/" + i18n.resolvedLanguage + t('navbar.routes.equipment') + "/" + t('solarEquipment.urlCategories.inverters')}>
                <ButtonComponent white image>
                  <h2>{t('home.equipment.types.2')}</h2>
                </ButtonComponent>
              </Link>
              <Link to={"/" + i18n.resolvedLanguage + t('navbar.routes.equipment') + "/" + t('solarEquipment.urlCategories.batteries')}>
                <ButtonComponent white image>
                  <h2>{t('home.equipment.types.3')}</h2>
                </ButtonComponent>
              </Link>
              {/* <Link to={"/" + i18n.resolvedLanguage + t('navbar.routes.equipment')+"/"+t('solarEquipment.urlCategories.carport')}>
                <ButtonComponent white image>
                  <h2>{t('home.equipment.types.4')}</h2>
                </ButtonComponent>
              </Link> */}
              <Link to={"/" + i18n.resolvedLanguage + t('navbar.routes.equipment') + "/" + t('solarEquipment.urlCategories.accessories')}>
                <ButtonComponent white image>
                  <h2>{t('home.equipment.types.5')}</h2>
                </ButtonComponent>
              </Link>
              <Link to={"/" + i18n.resolvedLanguage + t('navbar.routes.equipment') + "/" + t('solarEquipment.urlCategories.automatization')}>
                <ButtonComponent white image>
                  <h2>{t('home.equipment.types.6')}</h2>
                </ButtonComponent>
              </Link>
              <Link to={"/" + i18n.resolvedLanguage + t('navbar.routes.mountingSystems')}>
                <ButtonComponent white image>
                  <h2>{t('home.equipment.types.4')}</h2>
                </ButtonComponent>
              </Link>
              <Link to={"/" + i18n.resolvedLanguage + t('navbar.routes.equipment') + "/" + t('solarEquipment.urlCategories.cables_and_connectors')}>
                <ButtonComponent white image>
                  <h2>{t('home.equipment.types.7')}</h2>
                </ButtonComponent>
              </Link>
            </div>
            <div className="equipmentLogosContainer">
              <img src={jinko} alt={t('home.equipment.jinko')} />
              <img src={growatt} alt={t('home.equipment.growatt')} />
              <img src={staubli} alt={t('home.equipment.staubli')} />
              <img src={projoy} alt={t('home.equipment.projoy')} />
            </div>
            <div className="equipmentButton">
              <Link to={"/" + i18n.resolvedLanguage + t('navbar.routes.equipment')}><ButtonComponent wide >{t('home.equipment.button')}</ButtonComponent></Link>
            </div>
          </div>
          <CardComponent style={{ marginBottom: '5rem' }}>
            <div className="softwareContainer" >
              <div className="titleSoftware">
                <h2><TitleComponent smaller>{t('home.software.title')}</TitleComponent></h2>
                <h3><SubtitleComponent>{t('home.software.subtitle')}</SubtitleComponent></h3>
              </div>
              <div className="imageSoftware">
                <img src={softwareImage} alt={t('home.software.alt')} />
              </div>
              <div className="buttonSoftware">
                <Link to={"/" + i18n.resolvedLanguage + t('navbar.routes.software')}><ButtonComponent>{t('misc.learnMore')}</ButtonComponent></Link>
              </div>
            </div>
          </CardComponent>
          <div className="solarEquipment">
            <div className="titleEquipment">
              <h2>
                <TitleComponent centered moreSmaller>{t('home.chargers.title')}</TitleComponent>
              </h2>
              <h3><SubtitleComponent style={{ textAlign: "center", marginTop: "1rem" }} >{t('home.chargers.subtitle')}</SubtitleComponent></h3>
            </div>
            <div className="typesContainer">
              <Link to={"/" + i18n.resolvedLanguage + t('navbar.routes.chargers') + t('navbar.routes.evChargers')}>
                <ButtonComponent white image>
                  <h2>{t('chargersEquipment.categoriesDropdown.chargers')}</h2>
                </ButtonComponent>
              </Link>
              <Link to={"/" + i18n.resolvedLanguage + t('navbar.routes.chargers') + t('navbar.routes.chargerAccessories')}>
                <ButtonComponent white image>
                  <h2>{t('chargersEquipment.categoriesDropdown.chargerAccessories')}</h2>
                </ButtonComponent>
              </Link>
            </div>
            <div className="equipmentButton">
              <Link to={"/" + i18n.resolvedLanguage + t('navbar.routes.chargers')}><ButtonComponent wide>{t('misc.learnMore')}</ButtonComponent></Link>
            </div>
          </div>
          <CardComponent style={{ marginBottom: '4rem' }}>
            <div className="heatPumpsContainer">
              <div className="titleHeatPumps">
                <h2><TitleComponent smaller>{t('home.heatPumps.title')}</TitleComponent></h2>
                <h3><SubtitleComponent>{t('home.heatPumps.subtitle')}</SubtitleComponent></h3>
                <div className="heatPumpsLogosContainer">
                  <img src={gree} alt={t('heatPumps.logos.gree')} />
                  <img src={azuri} alt={t('heatPumps.logos.azuri')} />
                  <img src={mitsubishi} alt={t('heatPumps.logos.mitsubishi')} />
                </div>
              </div>
              <div className="imageHeatPumps">
                <img src={heatPumpsImage} alt={t('home.heatPumps.alt')} />
              </div>
              <div className="buttonHeatPumps">
                <Link to={"/" + i18n.resolvedLanguage + t('navbar.routes.heatPumps')}><ButtonComponent>{t('misc.learnMore')}</ButtonComponent></Link>
              </div>
            </div>
          </CardComponent>
          <div className="solarEquipment">
            <div className="titleEquipment">
              <h2>
                <TitleComponent centered moreSmaller>{t('mountingSystems.title')}</TitleComponent>
              </h2>
              <h3><SubtitleComponent style={{ textAlign: "center", marginTop: "1rem" }} >{t('mountingSystems.subtitle')}</SubtitleComponent></h3>
            </div>
            <div className="equipmentButton">
              <Link to={"/" + i18n.resolvedLanguage + t('navbar.routes.mountingSystems')}><ButtonComponent wide>{t('misc.learnMore')}</ButtonComponent></Link>
            </div>
          </div>
          <CardComponent>
            <div className="referencesContainer">
              <div className="titleHeatPumps">
                <h2><TitleComponent centered moreSmaller>{t('home.references.title')}</TitleComponent></h2>
                <div className="referenceItems">
                  <div className="referenceItem">
                    <Link to={"/" + i18n.resolvedLanguage + t('navbar.routes.project1')}><img src={pula1} alt="Integrated solar power plant" /></Link>
                    <SubtitleComponent centered white ralewayBold>{t('project1.title')}</SubtitleComponent>
                  </div>
                  <div className="referenceItem">
                    <Link to={"/" + i18n.resolvedLanguage + t('navbar.routes.project2')}><img src={a1} alt="Carport structure" /></Link>
                    <SubtitleComponent centered white ralewayBold>{t('project2.title')}</SubtitleComponent>

                  </div>pr
                  <div className="referenceItem">
                    <Link to={"/" + i18n.resolvedLanguage + t('navbar.routes.references')}><img src={buzet2} alt="Rooftop solar power system" /></Link>
                    <SubtitleComponent centered white ralewayBold>{t('references.buzet.title')}</SubtitleComponent>
                  </div>
                </div>
              </div>
              <div className="buttonHeatPumps" style={{ "textAlign": "center" }}>
                <Link to={"/" + i18n.resolvedLanguage + t('navbar.routes.references')}><ButtonComponent>{t('misc.learnMore')}</ButtonComponent></Link>
              </div>
            </div>
          </CardComponent>
        </ContainerComponent>
      </Trans>
    </div >

  )
}

export default withTranslation()(Home);
