import { create } from 'zustand';
import { Pagination, Product } from '../components/productCard/data';
import { decode } from 'html-entities';

interface Products {
    products: Product[],
    pagination: Pagination
}


interface ProductStore {
    solarPanels: Products;
    inverters: Products;
    batteries: Products;
    cables: Products;
    chargers: Products;
    charger_accessories: Products;
    product: Product | null;
    resetProduct: () => void;
    loading: boolean;
    error: string | null;
    fetchSolarPanels: (page: number) => Promise<void>;
    fetchInverters: (page: number) => Promise<void>;
    fetchBatteries: (page: number) => Promise<void>;
    fetchCables: (page: number) => Promise<void>;
    fetchChargers: (page: number) => Promise<void>;
    fetchChargerAccessories: (page: number) => Promise<void>;
    fetchProduct: (id: number) => Promise<void>;
    setLoading: (loading: boolean) => void;
}

// const backendUrl = 'https://api.tesenergy.hr'
const backendUrl = 'http://localhost:3001'

const useProductStore = create<ProductStore>((set) => ({
    solarPanels: { products: [], pagination: {} },
    batteries: { products: [], pagination: {} },
    inverters: { products: [], pagination: {} },
    cables: { products: [], pagination: {} },
    chargers: { products: [], pagination: {} },
    charger_accessories: { products: [], pagination: {} },
    product: null,
    resetProduct: () => set({ product: null }),
    loading: true,
    error: null,
    fetchSolarPanels: async (page: number) => {
        if (page === 0) {
            set({ loading: true, error: null });
        } fetch(`${backendUrl}/products/solar-products/page=${page}`, {
            method: 'GET',
            headers: { 'Accept': 'application/json' },
        })
            .then((response) => { if (!response.ok) throw new Error(); return response.json() })
            .then((result) => {
                return Promise.all(result['data'].map(async (product: any) => {
                    if (product.image && product.price) {
                        set((state) => ({
                            solarPanels: {
                                products: [...state.solarPanels.products, { id: product.id, brand: product.brand, title: product.title, desc: decode(product.desc), url: product.url, price: product.price, image: product.image, category: product.category, stock: product.stock }], pagination: result.pagination
                            }
                        }))
                    }

                }))

                    .then(() => {
                        set({ loading: false })
                    })
            })
            .catch((error) => {
                console.error('There was a problem with the fetch operation:', error);
                set({
                    loading: true, error: error
                })
            })
    },
    fetchInverters: async (page?: number) => {
        if (page === 0) {
            set({ loading: true, error: null });
        }
        fetch(`${backendUrl}/products/inverters/page=${page}`, {
            method: 'GET',
            headers: { 'Accept': 'application/json' },
        })
            .then((response) => { if (!response.ok) throw new Error(); return response.json() })
            .then((result) => {
                return Promise.all(result['data'].map(async (product: any) => {
                    if (product.image && product.price) {
                        set((state) => ({
                            inverters: {
                                products: [...state.inverters.products, { id: product.id, brand: product.brand, title: product.title, desc: decode(product.desc), url: product.url, price: product.price, image: product.image, category: product.category, stock: product.stock }], pagination: result.pagination
                            }
                        }))
                    }

                }))

                    .then(() => {
                        set({ loading: false })
                    })
            })
            .catch((error) => {
                console.error('There was a problem with the fetch operation:', error);
                set({
                    loading: true, error: error
                })
            })
    },
    fetchBatteries: async (page: number) => {
        if (page === 0) {
            set({ loading: true, error: null });
        }
        fetch(`${backendUrl}/products/batteries/page=${page}`, {
            method: 'GET',
            headers: { 'Accept': 'application/json' },
        })
            .then((response) => { if (!response.ok) throw new Error(); return response.json() })
            .then((result) => {
                return Promise.all(result['data'].map(async (product: any) => {
                    if (product.image && product.price) {
                        set((state) => ({
                            batteries: {
                                products: [...state.batteries.products, { id: product.id, brand: product.brand, title: product.title, desc: decode(product.desc), url: product.url, price: product.price, image: product.image, category: product.category, stock: product.stock }], pagination: result.pagination
                            }
                        }))
                    }

                }))

                    .then(() => {
                        set({ loading: false })
                    })
            })
            .catch((error) => {
                console.error('There was a problem with the fetch operation:', error);
                set({
                    loading: true, error: error
                })
            })
    },
    fetchCables: async (page: number) => {
        if (page === 0) {
            set({ loading: true, error: null });
        }
        fetch(`${backendUrl}/products/cables/page=${page}`, {
            method: 'GET',
            headers: { 'Accept': 'application/json' },
        })
            .then((response) => { if (!response.ok) throw new Error(); return response.json() })
            .then((result) => {
                return Promise.all(result['data'].map(async (product: any) => {
                    if (product.image && product.price) {
                        set((state) => ({
                            cables: {
                                products: [...state.cables.products, { id: product.id, brand: product.brand, title: product.title, desc: decode(product.desc), url: product.url, price: product.price, image: product.image, category: product.category, stock: product.stock }], pagination: result.pagination
                            }
                        }))
                    }

                }))

                    .then(() => {
                        set({ loading: false })
                    })
            })
            .catch((error) => {
                console.error('There was a problem with the fetch operation:', error);
                set({
                    loading: true, error: error
                })
            })
    },
    fetchChargers: async (page: number) => {
        if (page === 0) {
            set({ loading: true, error: null });
        }
        fetch(`${backendUrl}/products/chargers/page=${page}`, {
            method: 'GET',
            headers: { 'Accept': 'application/json' },
        })
            .then((response) => { if (!response.ok) throw new Error(); return response.json() })
            .then((result) => {
                return Promise.all(result['data'].map(async (product: any) => {
                    if (product.image && product.price) {
                        set((state) => ({
                            chargers: {
                                products: [...state.chargers.products, { id: product.id, brand: product.brand, title: product.title, desc: decode(product.desc), url: product.url, price: product.price, image: product.image, category: product.category, stock: product.stock }], pagination: result.pagination
                            }
                        }))
                    }

                }))

                    .then(() => {
                        set({ loading: false })
                    })
            })
            .catch((error) => {
                console.error('There was a problem with the fetch operation:', error);
                set({
                    loading: true, error: error
                })
            })
    },
    fetchChargerAccessories: async (page: number) => {
        if (page === 0) {
            set({ loading: true, error: null });
        }
        fetch(`${backendUrl}/products/charger-accessories/page=${page}`, {
            method: 'GET',
            headers: { 'Accept': 'application/json' },
        })
            .then((response) => { if (!response.ok) throw new Error(); return response.json() })
            .then((result) => {
                return Promise.all(result['data'].map(async (product: any) => {
                    if (product.image && product.price) {
                        set((state) => ({
                            charger_accessories: {
                                products: [...state.charger_accessories.products, { id: product.id, brand: product.brand, title: product.title, desc: decode(product.desc), url: product.url, price: product.price, image: product.image, category: product.category, stock: product.stock }], pagination: result.pagination
                            }
                        }))
                    }

                }))

                    .then(() => {
                        set({ loading: false })
                    })
            })
            .catch((error) => {
                console.error('There was a problem with the fetch operation:', error);
                set({
                    loading: true, error: error
                })
            })
    },
    fetchProduct: async (id: number) => {
        set({ loading: true, error: null });
        fetch(`${backendUrl}/products/${id}`, {
            method: 'GET',
            headers: { 'Accept': 'application/json' },
        })
            .then((response) => { if (!response.ok) throw new Error(); return response.json() })
            .then((result) => {
                if (result.image && result.price) {
                    set({
                        product: {
                            id: result.id, brand: result.brand, title: result.title, desc: decode(result.desc), url: result.url, price: result.price, image: result.image, category: result.category, stock: result.stock
                        }
                    })
                }
            })
            .then(() => {
                set({ loading: false })
            })
            .catch((error) => {
                // console.error('There was a problem with the fetch operation:', error);
                set({ error: error })
            })
    },
    setLoading: (loading) => set({ loading }),

}));

export function isImageFile(filename: string) {
    const imageExtensions = /\.(jpg|jpeg|png|webp)$/i;
    return imageExtensions.test(filename);
}

export const sortProductsByStock = (products: Product[]): Product[] => {
    return products.sort((a, b) => {
        if (a.stock === 0 && b.stock !== 0) {
            return 1;
        } else if (a.stock !== 0 && b.stock === 0) {
            return -1;
        } else {
            return 0;
        }
    });
};

export const fetchProductStock = async function (productId: Number) {
    let stock: any

    await fetch(`${backendUrl}/products/stock/${productId}`, {
        method: 'GET',
        headers: { 'Accept': 'application/json' },
    })
        .then((response) => {
            return response.json()
        })
        .then((result) => {
            stock = result
        }).catch((error) => {
            console.error('There was a problem with the fetch operation:', error);
        })

    return stock
};

export const stockAvailability = async function (warehouseId: Number) {
    let available = false;

    await fetch(`${backendUrl}/products/warehouses/${warehouseId}`, {
        method: 'GET',
        headers: { 'Accept': 'application/json' },
    })
        .then((response) => response.json())
        .then((result) => {
            if (result['ref'].endsWith("_out")) {
                available = false
            }
            else available = true

        })
        .catch((error) => {
            console.error('There was a problem with the fetch operation:', error);
        })
    return available
};

export const fetchCategory = async function (productId: Number) {
    let category = ''

    await fetch(`${backendUrl}/products/category/${productId}`, {
        method: 'GET',
        headers: { 'Accept': 'application/json' },
    })
        .then((response) => response.json())
        .then((result) => {
            if (result[1]) {
                category = result[1].label
            }

        })
        .catch((error) => {
            console.error('There was a problem with the fetch operation:', error);
        })

    return category
};

export const fetchProductImage = async function (productId: Number) {
    let image: string = ''
    let imagePath: string = ''
    let images: string[] = []

    await fetch(`${backendUrl}/products/product-image/id=${productId}`, {
        method: 'GET',
        headers: { 'Accept': 'application/json' },
    })
        .then((response) => response.json())
        .then((result) => {
            Promise.all(Object.keys(result).map((value: any) => {
                if (isImageFile(result[value].relativename)) {
                    image = result[value].relativename
                    imagePath = result[value].path
                    imagePath = imagePath.slice(imagePath.indexOf("/html") + "/html".length)
                    imagePath = 'https://pv.hr' + imagePath + '/' + image
                    images.push(imagePath)
                }
            }))
        })
        .then(() => {
            return images[0]
        })
        .catch((error) => {
            console.error('There was a problem with the fetch operation:', error);
        })
    return images[0]
};
export const findProductById = (products: Product[], id: string): Product | undefined => {
    return products.find(product => product.id === id);
};
export default useProductStore;